import React from "react";

function FullPageSpinner() {
  return (
    <div className="w-100 h-screen flex justify-center items-center bg-gray-700">
      <div className="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default FullPageSpinner;
