import React from "react";
import { getUser } from "../utils/user";

const AuthContext = React.createContext();

function AuthProvider(props) {
  const user = getUser();
  return <AuthContext.Provider value={user} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}

export { AuthProvider, useAuth };
