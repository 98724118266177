const getUser = () => {
  const user = window.localStorage.getItem("admin");

  if (user) {
    return JSON.parse(user);
  }

  return null;
};

const setUser = (user) => {
  window.localStorage.setItem("admin", JSON.stringify(user));
};

const clearUser = () => {
  window.localStorage.removeItem("admin");
};

export { getUser, setUser, clearUser };
