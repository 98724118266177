import React from "react";
import { useAuth } from "./contexts/AuthProvider";
import FullPageSpinner from "./pages/FullPageSpinner";

import "./styles/index.css";

const SecureLayout = React.lazy(() => import("./components/SecureLayout"));
const PublicLayout = React.lazy(() => import("./components/PublicLayout"));

function App() {
  const user = useAuth();

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {user ? <SecureLayout /> : <PublicLayout />}
    </React.Suspense>
  );
}

export default App;
